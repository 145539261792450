<template>
  <div class="hello">
    <div v-if="recentAirData" class="graph-container">
      <bar-visual
        v-if="recentAirData.o3.value > 0"
        :barData="{
          fullName: `Ozone`,
          description: speciesDescriptions.o3,
          name: `O`,
          sub: `3`,
          limit:whoLimit.day.o3
        }"
        v-bind:value="recentAirData.o3.value"
      ></bar-visual>
      <bar-visual
        v-if="recentAirData.no2.value > 0"
        :barData="{
          fullName: `Nitrogen dioxide`,
          description: speciesDescriptions.no2,
          name: `NO`,
          sub: `2`,
          limit:whoLimit.annual.no2
        }"
        v-bind:value="recentAirData.no2.value"
      ></bar-visual>
      <bar-visual
        v-if="recentAirData.so2.value > 0"
        :barData="{
          fullName: `Sulfur dioxide`,
          description: speciesDescriptions.so2,
          name: `SO`,
          sub: `2`,
          limit: whoLimit.day.so2
        }"
        v-bind:value="recentAirData.so2.value"
      
      ></bar-visual>
      <bar-visual
        v-if="recentAirData.pm10.value > 0"
        :barData="{
          fullName: `Particulate Matter`,
          description: speciesDescriptions.pm10,
          name: `PM`,
          sub: `10`,
          limit: whoLimit.annual.pm10
        }"
        v-bind:value="recentAirData.pm10.value"
      ></bar-visual>
      <bar-visual
        v-if="recentAirData.pm25.value > 0"
        :barData="{
          fullName: `Particulate Matter`,
          description: speciesDescriptions.pm25,
          name: `PM`,
          sub: `2.5`,
          limit: whoLimit.annual.pm25
        }"
        v-bind:value="recentAirData.pm25.value"
      ></bar-visual>
    </div>

    <div id="base" class="line">
      <hr />
      <p>0</p>
    </div>

    <div id="who" class="line">
      <hr />
      <p>WHO limit</p>
    </div>

    <div class="source" v-if="airData">
      <img src="@/assets/icons/info.png" alt="info" />
      <p v-if="usedStations">
        The above data was collected from:
        <span v-for="station in usedStations" v-bind:key="station.index">
          <br />
          ○ {{ station.sensorName }}, {{ station.readingInHoursAgo }} hours ago,
          which is {{ station.distance }}m away from you.
        </span>
      </p>
    </div>

    <div></div>
  </div>
</template>

<script>
import BarVisual from "@/components/visual/BarVisual.vue";

export default {
  components: {
    BarVisual,
  },
  name: "EventData",
  data() {
    return {
      species: null,
      recentAirData: {
        o3: {
          value: 0,
          sensorName: "",
          distance: 0,
        },
        no2: {
          value: 0,
          sensorName: "",
          distance: 0,
        },
        so2: {
          value: 0,
          sensorName: "",
          distance: 0,
        },
        pm10: {
          value: 0,
          sensorName: "",
          distance: 0,
        },
        pm25: {
          value: 0,
          sensorName: "",
          distance: 0,
        },
      },
      recentStations: {},
    };
  },
  methods: {
    getBarData() {},
  },
  computed: {
    airData() {
      if (this.$store.state.airData.length > 0) {
        return this.$store.state.airData;
      }
    },
    bestAirData() {
      //console.log('test ------ ', this.$store.state.evaluatedAirData)
      return this.$store.state.evaluatedAirData;
    },
    whoLimit() {
      return this.$store.state.whoLimit;
    },
    usedStations() {
      return this.$store.state.usedStations;
    },
    speciesDescriptions() {
      return this.$store.state.speciesDescriptions;
    },
  },
  watch: {
    bestAirData(obj) {
      this.recentAirData = obj;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.graph-container {
  display: flex;
  align-content: flex-end;
  justify-content: space-between;
  width: 85%;
  min-height: 200px;
  position: relative;
}

.source {
  margin-top: -50px;
  display: flex;
  align-items: flex-start;
}

.source p {
  font-size: 12px;
  font-style: italic;
  color: #6c6c6c;
  margin: 0;
}

.source img {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  padding-right: 10px;
}

.line {
  position: relative;
  top: -64px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  width: 100%;

  margin: 0;
  padding: 0;
}
.line hr {
  display: block;
  height: 2px;
  background-color: black;
  width: 400px;
  margin: 8px 0 0 0;
  padding: 0;
  flex-grow: 2;
  /* border-width: 1px;
  border-style: solid; */
  border: none;
}
.line p {
  font-weight: bold;
  display: inline-block;
  width: 6px;
  margin: 0;
  padding: 0px 20px;
}

#who {
  top: -135px;
}

@media only screen and (max-width: 800px) {
  .graph-container {
    width: 85%;
  }

  .line {
    width: 100%;
    top: -63px;
  }

  .line hr {
    width: 85%;
  }
  .source {
    font-size: 10px;
  }
  .source img {
    width: 15px;
    height: 15px;
  }

  #who {
    top: -130px;
  }
}
</style>
