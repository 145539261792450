<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  width: 100vw;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #000000;

  height: 100%;
  width: 100%;
}

h1 {
  font-weight: bold;
  font-size: 37px;
  margin: 0;
  padding: 50px 0px;
}

h2 {
  font-weight: bold;
  font-size: 28px;
  margin: 0;
  padding: 32px 0px 5px 0px;
}

@media only screen and (max-width: 800px) {
  h1 {
    font-weight: bold;
    font-size: 25px;
    margin: 0;
    padding: 15px 0px;
  }

  h2 {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    padding: 32px 0px 5px 0px;
  }
}
</style>
