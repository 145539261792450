<template>
  <head>
    <link
      href="https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css"
      rel="stylesheet"
    />
  </head>
  <div class="main" :style="{ width: dynamicWidth }">
    <div class="map-holder">
      <div id="map"></div>
    </div>
  </div>

  <div class="title">
    <h3>Intimate Air</h3>
    <a href="">By N O R M A L L Y</a>
  </div>
</template>

<script>
import axios from "axios";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import H from "@/js/species-helpers.js";

export default {
  name: "Map",
  data() {
    return {
      loading: false,
      geolocate: null,
      geocoder: null,
      access_token:
        "pk.eyJ1IjoiZmVsaXhyb3MiLCJhIjoiY2tydno0cDYxMGJkczJ1cW51ZGtqbGxndCJ9.alOQIsthk0GzVWsYQ1Mgig",
      center: [0, 0],
      markers: [],
      locationMarkers: [],
      dynamicWidth: null,
      dynamicHeight: null,
    };
  },
  created() {
    this.dynamicResize();
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.createMap();
  },
  methods: {
    async createMap() {
      try {
        mapboxgl.accessToken = this.access_token;
        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: this.center,
          zoom: 1,
        });

        //add a search field to the map
        this.geocoder = new MapboxGeocoder({
          accessToken: this.access_token,
          mapboxgl: mapboxgl,
          marker: false,
          compact: true,
        });

        this.map.addControl(this.geocoder);

        this.geocoder.on("result", (e) => {
          this.removeMarkers(this.locationMarkers);

          this.geolocate.track;

          const element = document.createElement("div");
          element.className = "personMarker";

          const marker = new mapboxgl.Marker(element, {
            draggable: true,
            color: "#1DA1F2",
          })
            .setLngLat(e.result.center)
            .addTo(this.map);

          this.locationMarkers.push(marker);

          this.center = e.result.center;
          console.log("dragged coords: ", e);
          let coords = e.result.center;

          this.$store.dispatch("updateCoords", [coords[1], coords[0]]);

          marker.on("dragend", (e) => {
            this.center = Object.values(e.target.getLngLat());
            let coords = Object.values(e.target.getLngLat());
            //console.log('dragged coords: ', coords)
            this.$store.dispatch("updateCoords", [coords[1], coords[0]]);
          });
        });

        // Add the control to the map.
        this.geolocate = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: false,
        });

        this.map.addControl(this.geolocate);
        this.map.on("load", () => {
          this.geolocate.trigger();
        });
        this.geolocate.on("geolocate", (e) => {
          if (typeof e !== "undefined") {
            this.$store.dispatch("updateCoords", [
              e.coords.latitude,
              e.coords.longitude,
            ]);
          }
        });
      } catch (err) {
        console.log("map error", err);
      }
    },
    async getLocation() {
      try {
        this.loading = true;
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.$store.state.userCoordinates[1]},${this.$store.state.userCoordinates[0]}.json?access_token=${this.access_token}`
        );
        this.loading = false;
        this.$store.dispatch("updateUserLocation", response.data);
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    myEventHandler(e) {
      // this.dynamicWidth = (window.outerWidth - 550).toString();
      // this.dynamicHeight = window.outerHeight.toString();
      // this.map.resize();
      this.dynamicResize();
      this.map.resize();
    },
    dynamicResize() {
      if (window.outerWidth > 800) {
        this.dynamicWidth = (window.outerWidth - 550).toString() + "px";
      } else {
        this.dynamicWidth = "100%";
      }

      this.dynamicHeight = window.outerHeight.toString() + "px";
    },
    removeMarkers(markerList) {
      if (markerList.length > 0) {
        markerList.forEach((marker) => {
          marker.remove();
        });
      }
    },
    async updateMarkers(sensorList) {
      //remove all previous markers
      this.removeMarkers(this.markers);
      //add new markers
      for (let i = 0; i < sensorList.length; i++) {
        const htmlStart =
          `<div class="popup" >
          <div class="top">
          <h1>` +
          sensorList[i].sensorName +
          `</h1>
          <div>
          <p>` +
          sensorList[i].readingInHoursAgo +
          ` hours ago, which is ` +
          sensorList[i].distance +
          `m away. </p>
          </div>
          </div>
          <ul>`;

        const speciesHtml = (type, sub, species) => {
          let str = "";
          if (sensorList[i].resentData[species] >= 0) {
            const col = H.colourVal(
              sensorList[i].resentData[species],
              this.$store.state.whoLimit.annual[species]
            );

            str =
              `<li>
            <div class="value" style="color:` +
              col +
              `;" >` +
              sensorList[i].resentData[species] +
              `</div>
            <div>` +
              type +
              `<sub>` +
              sub +
              `</sub></div>
            </li>`;
          }
          return str;
        };

        const htmlEnd = `</ul></div>`;

        let htmlStr =
          htmlStart +
          speciesHtml("O", "3", "o3") +
          speciesHtml("NO", "2", "no2") +
          speciesHtml("SO", "2", "so2") +
          speciesHtml("PM", "10", "pm10") +
          speciesHtml("PM", "2.5", "pm25") +
          htmlEnd;

        const popup = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
          closeOnClick: true,
        }).setHTML(htmlStr);

        const element = document.createElement("div");
        element.className = "stationMarker";

        let marker = new mapboxgl.Marker(element)
          .setLngLat([
            sensorList[i].sensorCoords[1],
            sensorList[i].sensorCoords[0],
          ])
          .setPopup(popup)
          .addTo(this.map);
        this.markers.push(marker);
      }
    },
  },
  computed: {
    coords() {
      return this.$store.state.userCoordinates;
    },
    sensorData() {
      if (this.$store.state.airData.length > 0) {
        let sensorList = [];
        this.$store.state.airData.forEach((station) => {
          sensorList.push(station);
        });

        return sensorList;
      } else {
        return null;
      }
    },
    userLocation() {
      return this.$store.state.userCoordinates;
    },
    bounds() {
      return this.$store.state.bounds;
    },
  },
  watch: {
    sensorData(sensorList) {
      if (sensorList) {
        this.updateMarkers(sensorList);
      }
    },
    bounds(bounds) {
      this.map.fitBounds([
        [this.$store.state.bounds[0][1], this.$store.state.bounds[0][0]],
        [this.$store.state.bounds[1][1], this.$store.state.bounds[1][0]],
      ]);
    },
    userLocation(coords) {
      this.getLocation();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.map-holder {
  width: auto;
  height: 100%;
}
#map {
  height: 100%;
}
.dislpay-arena {
  background: #ffffff;
  box-shadow: 0px -3px 10px rgba(0, 58, 78, 0.1);
  border-radius: 5px;
  padding: 20px 30px;
  /* width: 25%; */
}

.title {
  display: block;
  text-align: left;
  margin: 0;
  padding: 16px;
  top: 0;
  left: 0;
  position: fixed;
}
.title h3 {
  font-weight: bolder;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.title a {
  color: black;
  text-decoration: none;
  font-size: 10px;
  margin: 0;
  padding: 4px 1px;
  display: block;
}

@media only screen and (max-width: 800px) {
  .main {
    height: 65vh;
    width: 100vw;
    display: block;
    position: fixed;
    top: 0;
    /* z-index: -1; */
  }
  .map-holder {
    width: auto;
    height: 100%;
  }
  #map {
    height: 100%;
  }
}
</style>
<style>
.stationMarker {
  background-image: url("../assets/icons/station_icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;

  cursor: pointer;
}

.stationMarker:hover {
  transition: all 0.2s ease-in-out;
  height: 60px;
  width: 60px;
}

.personMarker {
  background-image: url("../assets/icons/person_icon_normal.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;

  cursor: pointer;
}

.personMarker:hover {
  background-image: url("../assets/icons/person_icon_waive.png");
  transition: all 0.2s ease-in-out;
  /* transition: width 0.2s ease-in-out; */
  height: 60px;
  width: 60px;
}

.personMarker:active {
  background-image: url("../assets/icons/person_icon_drag.png");
  transition: background-image 0.2s ease-in-out;
  height: 60px;
  width: 60px;
}

/* controls top right */
div.mapboxgl-control-container div.mapboxgl-ctrl-top-righ {
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl .mapboxgl-ctrl-geocoder {
}

/* search field */
.mapboxgl-ctrl-geocoder {
}

/* geolocate */
.mapboxgl-ctrl .mapboxgl-ctrl-group {
}
.mapboxgl-ctrl-geolocate {
}
@media only screen and (max-width: 600px) {
  /* search field */
  .mapboxgl-ctrl-geocoder {
    max-width: 220px;
  }
}

/* pop up */
.mapboxgl-popup-content {
  display: block;

  width: 355px;

  border-radius: 16px;
  border-top-left-radius: 16px;
}
div.popup {
  padding: 8px;
  margin: 0px;
}

.popup .top {
  width: 100%;
}

.popup .top h1 {
  color: black;
  font-size: 18px;
  font-weight: bold;
  max-width: 100%;
  line-height: 22px;
  padding: 5px;
  margin: 0px;
}
.popup .top div {
  padding: 5px;
  margin: 0px;
}
.popup .top div p {
  font-size: 16px;
  font-style: italic;
  color: #6c6c6c;
  margin: 0px;
  margin-bottom: 5px;
}

.popup ul {
  display: flex;
  direction: row;
  justify-content: flex-start;
  list-style: none;
  text-decoration: none;
  padding: 5px 0px;
  margin: 0px;
  width: auto;
}
.popup ul li {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 50px;
  height: 50px;
  padding: 5px 15px 0px 5px;
}

.popup ul li div {
  font-size: 22px;
  text-decoration: none;
  text-align: center;
  padding: 5px;
}
.popup ul li div.value {
  font-size: 20px;
  font-weight: bold;
}
.popup ul li div p {
  font-weight: bold;
}
.popup ul li sub {
  font-size: 14px;
}
</style>
