<template>
  <div class="accordion">
    <vue-collapsible-panel-group base-color="#FFFFFF">
      <vue-collapsible-panel :expanded="false">
        <template #title> Panel A Title </template>
        <template #content> Panel A Content </template>
      </vue-collapsible-panel>

      <vue-collapsible-panel :expanded="false">
        <template #title> Panel B Title </template>
        <template #content> Panel B Content </template>
      </vue-collapsible-panel>
    </vue-collapsible-panel-group>
  </div>
</template>
<script>
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from "@dafcoe/vue-collapsible-panel";
import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";

export default {
  name: "Accordion",
  components: {
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    accordionContent() {
      const item = (title, description) => {
        item = {
          ...item,
          title: title,
          description: description,
        };
        return item;
      };
    },
  },
};
</script>
<style scoped>
.accordion {
  padding: 16px 0px;
}
</style>
<style>
.vcpg[data-v-63943e43] .vcp__header {
  justify-content: space-between;
  padding: 0px;
  border-width: 0px 0px 1px 0px;

  border-color: black;
  border-style: solid;
}
.vcpg[data-v-63943e43] .vcp__header:hover {
  background-color: var(--bg-color-header-hover);
}
.vcpg[data-v-63943e43] .vcp__header:active {
  background-color: var(--bg-color-header-active);
}
.vcpg[data-v-63943e43] .vcp__header-title {
  font-weight: bold;
}
.vcp__body-content[data-v-64321428] {
  padding: 8px 0px;
}

/* .vcpg[data-v-63943e43] {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 100%}
.vcpg[data-v-63943e43] .vcp * {
    box-sizing: border-box;
}

.vcpg[data-v-63943e43] .vcp:not(:first-of-type) .vcp__header {
    border-top: 1px solid var(--border-color);
}
.vcpg[data-v-63943e43] .vcp__header-title {
    font-weight: 500;
}
.vcpg[data-v-63943e43] .vcp__body {
    border-top: 1px solid var(--border-color);
    background-color: var(--bg-color-body);
}
.vcp__header[data-v-64321428] {
    display: flex;
    justify-content: ;
    padding: 12px;
    pointer-events: none;
}
.vcp--expandable .vcp__header[data-v-64321428] {
    pointer-events: auto;
    cursor: pointer;
}
.vcp__header-title[data-v-64321428] {
    display: flex;
    align-items: center;
    flex: 1;
}
.vcp__header-icon[data-v-64321428] {
    display: flex;
    align-items: center;
    transition: transform .3s cubic-bezier(.5, .25, 0, 1);
}
.vcp--expanded .vcp__header-icon[data-v-64321428] {
    transform-origin: center;
    transform: rotate(180deg);
}
.vcp__header-icon>span[data-v-64321428] {
    display: flex;
    height: 24px;
    width: 24px;
}
.vcp__header-icon[data-v-64321428] svg {
    height: 100%;
    width: 100%}
.vcp__body[data-v-64321428] {
    overflow: hidden;
    transition: all .3s cubic-bezier(.5, .25, 0, 1);
}

.slide-enter-from[data-v-64321428], .slide-leave-to[data-v-64321428] {
    opacity: .25;
} */
</style>
