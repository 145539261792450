import { createStore } from "vuex";

import state from "./state";
import mutations from "./mutations";
import actions from "./actions";

//modules
import MapStore from "./modules/MapStore";

export default createStore({
  modules: {
    MapStore,
  },
  state,
  mutations,
  actions
});
