<template>
  <Map></Map>
  <side-panel></side-panel>

 
</template>

<script>
// @ is an alias to /src
import Map from "@/components/Map.vue";
import SidePanel from "../components/SidePanel.vue";

export default {
  name: "Home",
  components: {
    Map,
    SidePanel,
  },
};
</script>
<style scoped>


</style>
