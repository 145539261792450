<template>
  <div class="container">
    <p>{{ userLocation.address }}</p>
    <p>{{ userLocation.city }}</p>
    <p>{{ userLocation.country }}</p>
  </div>
</template>
<script>
export default {
  name: "UserAddress",
  data() {
    return {};
  },
  methods: {},
  computed: {
    userLocation() {
      return this.$store.state.userLocation;
    },
  },
  watch: {},
};
</script>
<style scoped>
.container{
    text-align: left;
    display: block;
    
    position: relative;
}
.container p {
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    color: #6c6c6c;
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 800px) {

.container p {
  font-size: 14px;
}
}

</style>
