<template>
  <div class="box-half">
   
    <div class="headers">
      <p
        v-for="speciesLimit in speciesLimitList"
        v-bind:key="speciesLimit.index"
      >
        <b>{{ speciesLimit.name }}</b> is <b>{{ speciesLimit.multiplier }}x</b> the <u> {{ effectType }} limit </u>
      </p>
    </div>
     <h3>{{ header }}</h3>
     <p>Some text on why this is bad for you.</p>
  </div>
</template>
<script>
export default {
  name: "UserEffects",
  props: {
    header: "",
    effectType: "",
  },
  data() {
    return {};
  },
  computed: {
    speciesLimitList() {
      let overLimitArray = [];

      let checkLimit = (species, limitType) => {
        if (
          this.$store.state.evaluatedAirData[species] &&
          this.$store.state.whoLimit[limitType][species]
        ) {
          let limit = this.$store.state.whoLimit[limitType][species];
          let speciesVal = this.$store.state.evaluatedAirData[species].value;

          if (speciesVal > limit && limit > 0) {
            const overLimit = {
              ...overLimit,
              value: speciesVal,
              limit: limit,
              multiplier: (speciesVal / limit).toFixed(1),
              name: species,
            };

            overLimitArray.push(overLimit);
          }
        } else {
          return null;
        }
      };

      const o3 = checkLimit("o3", this.effectType);
      const no2 = checkLimit("no2", this.effectType);
      const so2 = checkLimit("so2", this.effectType);
      const pm10 = checkLimit("pm10", this.effectType);
      const pm25 = checkLimit("pm25", this.effectType);

      return overLimitArray;
    },
  },
};
</script>
<style scoped>
.box-half{
    padding-right: 8px;
}
.headers {

    font-size: 18px;
    
    color: black;
    margin: 0;
    padding: 0;
}

h3 {
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    color: #6c6c6c;
    margin: 0;
    padding: 0;
}

</style>
