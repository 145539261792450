<template>
  <h2>{{ title }}</h2>
  <hr />
</template>
<script>
export default {
  name: "VueHeader",
  props:{
      title: "",
  }
  
};
</script>
<style scoped>
hr {
  display: block;
  height: 1px;
  background-color: black;
  width: 100%;
  margin: 4px 0 0 0;
  padding: 0;
  border: none;
}
</style>
