<template></template>

<script>
import AqicnService from "@/services/AqicnService.js";

export default {
  name: "DataReceiver",
  components: {},
  data() {
    return {};
  },
  created() {
    //   AqicnService.getTest()
    //     .then((response) => {
    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // this.$getLocation(options).then((coordinates) => {
    //   console.log(coordinates);
    // });
  },
  methods: {},
  computed: {
    userCoords() {
      return this.$store.state.userCoordinates;
    },
    closestStation() {
      if (this.$store.state.closestStation != {}) {
        return this.$store.state.closestStation;
      }
    },
    bounds() {
      return this.$store.state.bounds;
    },
    stationIds() {
      if (this.$store.state.stationIds.length > 0) {
        return this.$store.state.stationIds;
      }
    },
    airData() {
      return this.$store.state.airData;
    },
  },
  watch: {
    userCoords(coords) {
      AqicnService.getGeoFeed(coords)
        .then((response) => {
          this.$store.dispatch("updateClosestStation", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closestStation(stationInfo) {
      this.$store.dispatch("updateBounds", stationInfo);
    },
    bounds(b) {
      AqicnService.getGeoBoundsFeed(b[0], b[1], b[2], b[3])
        .then((response) => {
          this.$store.dispatch("updateStationIds", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    stationIds(ids) {
      this.$store.dispatch("updateAirData", null);

      ids.forEach((id) => {
        AqicnService.getStationById(id)
          .then((response) => {
            this.$store.dispatch("updateAirData", response.data);
            this.$store.dispatch("updateEvalutedAirData");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
