<template>
  <div class="tooltip" v-if="show">
    <p class="header">
      {{ tooltipData.fullName }} 
      ({{ tooltipData.name }}<sub> {{ tooltipData.sub }} </sub>)
    </p>
    <p>{{ tooltipData.description }}</p>
  </div>
</template>
<script>
export default {
  name: "SpeciesTooltip",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    tooltipData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.tooltip {
  z-index: 99;
  padding: 16px;
  position: absolute;
  background-color: white;
  margin: 0px -0px 0px 0px;
  bottom: -160px;

  display: inline-block;

  height: auto;
  width: 225px;
  height: 140px;

  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 58, 78, 0.3);
}
.tooltip p.header {
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  color: black;
  margin: 0px 0px 4px 0px;
  padding: 0;
}
.tooltip p {
  font-size: 12px;
  font-style: italic;
  color: #6c6c6c;
  margin: 0;
}
</style>
