import H from "@/js/store-helpers.js";

const actions = {
  updateCoords({ commit, dispatch }, coordinates) {
    let coords = [coordinates[0], coordinates[1]];

    const distFromOldLocation = H.measureDistance(
      coords[0],
      coords[1],
      this.state.userCoordinates[0],
      this.state.userCoordinates[1]
    );

    const secondsSinceLastUpdate = Math.floor(
      ((Date.now() - this.state.lastSensorUpdate) / 1000) % 60
    );

    const setLocationVals = (coords) => {
      commit("SET_COORDS", coords);
      commit("SET_LAST_SENSOR_UPDATE", Date.now());
    };

    if (this.state.lastSensorUpdate == null) {
      //if no sensor has been set
      setLocationVals(coords);
      //   commit("SET_COORDS", coords);
      //   commit("SET_LAST_SENSOR_UPDATE", Date.now());
    } else if (distFromOldLocation > 10) {
      //if the new position is more than 10 m away from the old position
      setLocationVals(coords);
    } else if (secondsSinceLastUpdate > 1) {
      //make sure atleast one minute has past before updating
      setLocationVals(coords);
    }
    // commit("SET_COORDS", coords);
    //   commit("SET_LAST_SENSOR_UPDATE", Date.now());
  },
  updateClosestStation({ commit }, rawData) {
    const stationInfo = {
      ...stationInfo,
      coordinates: rawData.data.city.geo,
      distance: H.measureDistance(
        this.state.userCoordinates[0],
        this.state.userCoordinates[1],
        rawData.data.city.geo[0],
        rawData.data.city.geo[1]
      ),
    };

    commit("SET_CLOSEST_STATION", stationInfo);
  },
    updateUserLocation({ commit }, data) {

      let locationArray = data.features[0].place_name.split(",") //split is more robust depending address format
      const userLocation = {
        ...this.userLocation,
        address: locationArray[0],
        city: locationArray[1],
        country: locationArray[2],
      };

      commit("SET_USER_LOCATION", userLocation);
    },
  updateStationIds({ commit }, response) {
    let idArray = [];
    if (response.data.length > 0) {
      response.data.forEach((element) => {
        idArray.push(element.uid);
      });
      commit("SET_STATION_IDS", idArray);
    }
  },
  updateAirData({ commit }, rawData) {
    if (rawData == null) {
      commit("CLEAR_AIR_DATA");
    } else {
      const sensorData = {
        ...this.sensorData,
        sensorCoords: rawData.data.city.geo,
        sensorName: rawData.data.city.name,
        sensorRecordingDateAndTime: rawData.data.time.iso,
        readingInHoursAgo: H.hoursAgo(rawData.data.time.iso),
        resentData: {
          o3: H.checkAqiValue(rawData.data.iaqi.o3),
          no2: H.checkAqiValue(rawData.data.iaqi.no2),
          so2: H.checkAqiValue(rawData.data.iaqi.so2),
          pm10: H.checkAqiValue(rawData.data.iaqi.pm10),
          pm25: H.checkAqiValue(rawData.data.iaqi.pm25),
        },
        distance: H.measureDistance(
          this.state.userCoordinates[0],
          this.state.userCoordinates[1],
          rawData.data.city.geo[0],
          rawData.data.city.geo[1]
        ),
        rawData: rawData.data,
      };
      commit("SET_AIR_DATA", sensorData);
    }
  },
  updateBounds({ commit }, stationInfo) {
    //let autoDist = H.clamp(stationInfo.distance * 3, 1000, 10000);

    let autoDist;
    //if the closest station is undre 10km
    if (stationInfo.distance < 10000) {
      autoDist = H.clamp(stationInfo.distance * 3, 1000, 10000);
    } else {
      autoDist = stationInfo.distance * 1.05;
    }

    const bounds = H.offsetBounds(
      this.state.userCoordinates[0],
      this.state.userCoordinates[1],
      autoDist
    );

    commit("SET_MAP_BOUNDS", bounds);
  },
  updateEvalutedAirData({ commit }) {
    try {
      if (this.state.airData.length > 0) {
        let usedStations = [];

        let getBestValue = (species) => {
          //find stations that contain the species
          let validStations = H.validStations(species, this.state.airData);

          // take the station with the heights score
          let bestStation = H.bestStation(validStations);

          //console.log("best station: ", bestStation);
          if (bestStation != null) {
            let shouldAdd = true;
            usedStations.forEach((station) => {
              if (station.sensorName == bestStation.sensorName) {
                shouldAdd = false;
              }
            });
            if (shouldAdd) {
              usedStations.push(bestStation);
            }
          }

          let bestVal = null;

          if (bestStation == null) {
            bestVal = {
              ...this.bestVal,
              value: 0,
              sensorName: "Oops, there is no relieble sensor data available",
              distance: "",
            };
            return bestVal;
          } else {
            bestVal = {
              ...this.bestVal,
              value: bestStation.resentData[species],
              sensorName: bestStation.sensorName,
              distance: bestStation.distance,
            };

            return bestVal;
          }
        };

        let evaluatedAirData = {
          ...this.evaluatedAirData,
          o3: getBestValue("o3"),
          no2: getBestValue("no2"),
          so2: getBestValue("so2"),
          pm10: getBestValue("pm10"),
          pm25: getBestValue("pm25"),
        };
        //console.log("used station: " + usedStations.length);
        commit("SET_USED_STATIONS", usedStations);

        commit("SET_EVALUTAED_DATA", evaluatedAirData);
      }
    } catch (error) {
      console.log("evalutaion error", error);
    }
  },
};

export default actions;
