const mutations = {
  SET_COORDS(state, coords) {
    state.userCoordinates = coords;
    console.log('-----> SETTING COORDINATES: ' + state.userCoordinates)
  },
  SET_LAST_SENSOR_UPDATE(state, now){
    state.lastSensorUpdate = now;
    
  },
  SET_USER_LOCATION(state, location) {
    state.userLocation = location;
    console.log('-----> SETTING USER LOCATION: ' + state.userLocation)
  },
  SET_CLOSEST_STATION(state, stationInfo) {
    state.closestStation = stationInfo;
    console.log('-----> SETTING CLOSEST STATION: ' + state.closestStation)
  },
  SET_AIR_DATA(state, data) {
    state.airData.push(data);
    console.log('-----> SETTING AIR DATA: ' + state.airData)
  },
  CLEAR_AIR_DATA(state) {
    state.airData = [];
  },
  SET_STATION_IDS(state, ids) {
    state.stationIds = ids;
  },
  SET_MAP_BOUNDS(state, bounds) {
    state.bounds = bounds;
  },
  SET_EVALUTAED_DATA(state, data){
      state.evaluatedAirData = data;
      console.log('-----> SETTING EVALUTED DATA: ' + data)
  },
  SET_USED_STATIONS(state, stations){
      state.usedStations = stations;
      console.log('-----> SETTING USED STATIONS: ' + stations)
  }
};
export default mutations;
