import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://api.waqi.info",
  withCredentials: false,
});

const token = "6ddf177425a838c35546734af83fefb0e03e7e8d";

export default {
  getGeoFeed(coords) {
    return apiClient.get(
      "/feed/geo:" + coords[0] + ";" + coords[1] + "/?token=" + token
    );
  },
  getGeoBoundsFeed(southWestBounds, NorthEastBounds) {
    return apiClient.get(
      "/map/bounds/?latlng=" +
        southWestBounds[0] +
        "," +
        southWestBounds[1] +
        "," +
        NorthEastBounds[0] +
        "," +
        NorthEastBounds[1] +
        "&token=" +
        token
    );
  },
  getStationById(id) {
    return apiClient.get("/feed/@" + id + "/?token=" + token);
  },
};
