const H = {};

H.mutliplier = (value, limit) => {
  const multi = value / limit;
  return multi;
};

H.colourVal = (value, limit) => {
  const multi = H.mutliplier(value, limit);

  if (multi > 1.5) {
    return "#ED8E8E";
  } else if (multi > 0.75) {
    return "#EDCD8E";
  } else {
    return "#8ECBA7";
  }
};

export default H;
