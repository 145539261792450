const H = {};

//evalutation helpers
H.validStations = (species, airData) => {
  //add stations that contain the species you want
  let validStations = [];
  airData.forEach((station) => {
    if (station.resentData[species] >= 0) {
      validStations.push(station);
    }
  });
  return validStations;
};

H.bestStation = (stations) => {
  let score = (station) => {
    //how far away is the station? 1km is 1
    const distKM = station.distance / 1000;
    //how old is the data in this station in hours
    const hoursAgo = H.hoursAgo(station.sensorRecordingDateAndTime);

    //  console.log("km away: ", distKM);
    //  console.log("hours ago: ", hoursAgo);

    return distKM + hoursAgo;
  };

  if (stations.length > 0) {
    if (stations.length == 1) {
      //if there is only one station just return the one.
      return stations[0];
    } else {
      let best = null;
      let lowestScore = 9999;

      stations.forEach((station) => {
        let newScore = score(station);

        if (newScore < lowestScore) {
          lowestScore = newScore;
          best = station;
        }
      });

      return best;
    }
  } else {
    return null;
  }
};

H.hoursAgo = (dateTime) => {
  return (
    -1 *
    Math.floor(((Date.parse(dateTime) - Date.now()) / (1000 * 60 * 60)) % 24)
  );
};

//math helpers
H.clamp = (num, min, max) => {
  return Math.min(Math.max(num, min), max);
};

//coordinate stuff
H.measureDistance = (lat1, lon1, lat2, lon2) => {
  var R = 6378.137; // Radius of earth in KM
  var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return (d * 1000).toFixed(0); // meters
};

H.offsetBounds = (userLat, userLon, offset) => {
  //Earth’s radius, sphere
  const R = 6378137;

  let offsetCoord = (o) => {
    const dn = o;
    const de = o;
    //Coordinate offsets in radians
    let dLat = dn / R;
    let dLon = de / (R * Math.cos((Math.PI * userLat) / 180));
    //OffsetPosition, decimal degrees
    let latO = userLat + (dLat * 180) / Math.PI;
    let lonO = userLon + (dLon * 180) / Math.PI;
    //offsets in meters
    return [latO, lonO];
  };

  const southWest = offsetCoord(-offset);
  const northEast = offsetCoord(offset);

  return [southWest, northEast];
};

//api value helpers
H.checkAqiValue = (obj) => {
  if (typeof obj !== "undefined") {
    return obj.v;
  } else {
    return -10;
  }
};

H.cleanDateAndTime = (str) => {
  return str.replace("00:00", "00");
};

export default H;
