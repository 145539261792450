<template>
  <div class="container">
    <vue-header :title="`What does this data mean?`" />
    <div class="effects-container">
      <user-effects
        class="effects-box"
        :effectType="`day`"
        :header="`short term`"
      />

      <user-effects :effectType="`annual`" :header="`long term`" />
    </div>
  </div>
</template>
<script>

import UserEffects from "./visual/UserEffects.vue";
import VueHeader from "./visual/vueHeader.vue";
export default {
  components: { UserEffects, VueHeader },
  name: "DataMeaning",
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style scoped>
.container {
  padding: 0;
  margin: 32px 0px 0px 0px;
}

.effects-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.effects-box {
  display: block;
  width: 50%;
}
</style>
