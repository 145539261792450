<template>
  <div class="bar-container">
    <div class="muliplier">
      <p class="num">{{ roundedMultiplier }}</p>
      <p class="x">X</p>
    </div>

    <div
      class="bar"
      v-bind:style="{
        height: ani.animatedHeight + 'px',
        backgroundColor: ani.animatedColor,
      }"
    ></div>
    <p
      class="species-name"
      @mouseover="tooltip = true"
      @mouseleave="tooltip = false"
    >
      {{ barData.name }}<sub> {{ barData.sub }} </sub>
    </p>

    <species-tooltip
      v-bind:show="tooltip"
      :tooltipData="barData"
    ></species-tooltip>
  </div>
</template>
<script>
import H from "@/js/species-helpers.js";
import anime from "animejs/lib/anime.es.js";
import SpeciesTooltip from "./SpeciesTooltip.vue";

export default {
  name: "BarVisual",
  components: {
    SpeciesTooltip,
  },
  props: {
    barData: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      multiplier: 0,
      ani: {
        animatedHeight: 0,
        animatedColor: "#8ECBA7",
      },
      tooltip: false,
    };
  },
  created() {
    this.updateVisual();
  },

  methods: {
    updateVisual() {
      this.multiplier = H.mutliplier(this.value, this.barData.limit);
      const barHeight = this.multiplier * 50;

      anime({
        targets: this.ani,
        animatedHeight: barHeight,
        animatedColor: this.dynamicColour,
        easing: "easeInOutQuad",
        duration: 800,
      });

      return;
    },
  },
  computed: {
    dynamicColour() {
      return H.colourVal(this.value, this.barData.limit);
    },
    roundedMultiplier() {
      return this.multiplier.toFixed(1);
    },
  },
  watch: {
    value() {
      this.updateVisual();
    },
  },
};
</script>
<style scoped>
species-tooltip {
}

.ani-bar-enter-active {
  transition: all 0.3s ease;
}
.ani-bar-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.ani-bar-enter, .ani-bar-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.bar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  cursor: default;
}
.bar {
  width: 10px;
  background-color: #42b983;
  border-radius: 10px 10px 0px 0px;
}
.species-name {
  font-size: 32px;
  font-weight: normal;
  text-align: center;
  padding: 0;
  margin: 0;

  line-height: 45px;
}
.species-name sub {
  font-size: 20px;
}
.multiplier {
  display: block;

  margin: auto;
  text-align: center;
}
.num {
  font-size: 24px;
  display: inline;
  font-weight: bold;
}
.x {
  font-size: 10px;
  display: inline;
  font-weight: bold;
}

@media only screen and (max-width: 800px) {
  .species-name {
    font-size: 24px;

    line-height: 45px;
  }
  .species-name sub {
    font-size: 16px;
  }

  .num {
    font-size: 20px;
  }
  .x {
    font-size: 8px;
  }
}
</style>
