const state = {
  accesToken:
    "pk.eyJ1IjoiZmVsaXhyb3MiLCJhIjoiY2tydno0cDYxMGJkczJ1cW51ZGtqbGxndCJ9.alOQIsthk0GzVWsYQ1Mgig",
  loading: false,
  userLocation: {
    address: "",
    city: "",
    country: "",
  },
  stations: [],
};

const getters = {};

const mutations = {
  SET_USER_LOCATION2(state, location) {
    state.userLocation = location;
    console.log("-----> SETTING USER LOCATION: " + state.userLocation);
  },
};

const actions = {

  // updateStations({ commit }, airData){

  //   let sensorList = [];
  //   airData.forEach((station) => {
  //     sensorList.push(station);
  //   });





  // }

//   async updateLocation({ commit }, coords) {
//     try {
//       this.state.loading = true;
//       const response = await axios.get(
//         `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords[1]},${coords[0]}.json?access_token=${this.state.accesToken}`
//       );
//       this.state.loading = false;
//       //this.$store.dispatch("updateUserLocation", response.data);

//       const userLocation = {
//         ...this.userLocation,
//         address:
//           response.data.features[0].address +
//           " " +
//           response.data.features[0].text,
//         city: response.data.features[4].text,
//         country: response.data.features[7].text,
//       };

//       commit("SET_USER_LOCATION", userLocation);
//     } catch (err) {
//       this.loading = false;
//       console.log(err);
//     }
//   },
  test({ commit }, temp) {

    console.log("THIS RUNS");
  },
};

export default { state, getters, mutations, actions };
