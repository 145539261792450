<template>
  <div class="side-container">
    <div class="content">
      <h1>
        What air am I breathing — <br />
        right here, right now?
      </h1>

      <user-address></user-address>

      <data-receiver></data-receiver>
      <data-visual></data-visual>
      <!-- <data-meaning />
      <accordion /> -->
      
    </div>
  </div>
</template>

<script>
import DataReceiver from "@/components/data/DataReceiver.vue";
import DataVisual from "@/components/DataVisual.vue";
import UserAddress from "./visual/UserAddress.vue";
import DataMeaning from './DataMeaning.vue';
import Accordion from './visual/accordion.vue';

export default {
  components: {
    DataReceiver,
    DataVisual,
    UserAddress,
    DataMeaning,
    Accordion,
  },
  name: "SidePanel",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.side-container {
  width: 550px;
  height: 100%;
  display: inline-block;
  background-color: rgb(255, 255, 255);
  vertical-align: top;
}

.content {
  margin: 0px;
  padding: 32px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}


@media only screen and (max-width: 800px) {
  .side-container {
    height: 200%;
    width: 100vw;
    box-shadow: 0px -3px 10px rgba(0, 58, 78, 0.1);
    border-radius: 16px;
    display: block;
    top: 60vh;
    margin: 0 0 0 0;
    position: absolute;
  }
}
</style>
