const state = {
  userCoordinates: [0, 0],
  bounds: [0, 0, 0, 0],
  userLocation: {
    address: "",
    city: "",
    country: "",
  },
  lastSensorUpdate: null,
  closestStation: {},
  stationIds: [],
  airData: [],
  sensorData: {
    sensorCoords: [],
    sensorName: "",
    sensorRecordingDateAndTime: "",
    resentData: {
      no2: 0,
      o3: 0,
      so2: 0,
      pm10: 0,
      pm25: 0,
    },
    distance: 0,
    rawData: null,
  },
  evaluatedAirData: {
    o3: {},
    no2: {},
    so2: {},
    mp10: {},
    mp25: {},
  },
  usedStations: [],
  speciesDescriptions: {
    no2: "Is poisonous and harmful to all forms of life just like chlorine gas poisoning and carbon monoxide poisoning. It is easily absorbed through the lungs and its inhalation can result in heart failure and sometimes death in severe cases.",
    o3: "When inhaled, ozone can damage the lungs. Relatively low amounts can cause chest pain, coughing, shortness of breath and throat irritation. Ozone may also worsen chronic respiratory diseases such as asthma and compromise the ability of the body to fight respiratory infections.",
    so2: "affects the respiratory system, particularly lung function, and can irritate the eyes. Sulfur dioxide irritates the respiratory tract and increases the risk of tract infections. It causes coughing, mucus secretion and aggravates conditions such as asthma and chronic bronchitis.",
    pm10: "PM10 particles are so small that they effectively act as a gas. Exposure to high concentrations of PM10 can result in a number of health impacts ranging from coughing and wheezing to asthma attacks and bronchitis to high blood pressure, heart attack, strokes and premature death.",
    pm25: "PM2.5 particles are able to travel deeply into the respiratory tract, reaching the lungs. Exposure to fine particles can cause short-term health effects such as eye, nose, throat and lung irritation, coughing, sneezing, runny nose and shortness of breath.",
  },
  whoLimit: {
    annual: {
      no2: 40,
      o3: -1,
      so2: -1,
      pm10: 20,
      pm25: 10,
    },
    day: {
      no2: -1,
      o3: 100,
      so2: 20,
      pm10: 50,
      pm25: 25,
    },
    hour: {
      no2: 200,
      o3: 150,
      so2: -1,
      pm10: -1,
      pm25: -1,
    },
  },
  colour: {
    good: "#8ECBA7",
    medium: "#EDCD8E",
    bad: "#ED8E8E",
    grey: "#6C6C6C",
  },
};

export default state;
